.container{
    margin: 10px;
    font-family: 'Inter';
}
.titulo{
    font-size: 24px;
    margin-bottom: 20px;
}
.bodyTerminos{
    text-align: justify;
}
.bodyTerminos p{
    margin-left: 40px;
    margin-top: 10px;
}
.bodyTerminos h4{
    margin-left: 20px;
    font-size: 20px;
    margin-top: 20px;
}
.btn-terminos{
    margin-left: 40px;
    margin-bottom: 20px;
}
