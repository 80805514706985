.banner-principal{
    width: 100%;
    height: 390px;
    background: linear-gradient(0deg, rgba(9, 39, 75, 0.774), rgba(14, 39, 68, 0.781)), url("../assets/cmc.jpg") center no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}
.texto-banner{
    width: 60%;
    margin: 0 auto;
    padding-top: 100px;
    color: white;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    align-items: center;
}
.texto-banner h1{
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    line-height: 87px;
    display: flex;
    align-items: center;
    padding-bottom: 30px;
}
@media only screen and (max-width: 768px){

   
    .texto-banner{
        padding-top: 45px;
        text-align: center;
        width: 60%;
    }
    .texto-banner p{
        display: none;
        }
    .texto-banner h1{
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 44px;
        display: flex;
        align-items: center;
        padding-bottom: 30px;
    }
    .banner-principal{
        width: 100%;
        height: 290px;
        background: linear-gradient(0deg, rgba(9, 39, 75, 0.774), rgba(14, 39, 68, 0.781)), url("../assets/cmc.jpg") center no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

  }