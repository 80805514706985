nav {
    width: 60%;
    height: 50px;
    background-color: #345984;
    margin: 0;
    padding: 0;
    color: white;
    margin: 0 auto;
    font-family: inter;
    font-size: 80%;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}
svg{
    fill: white;
    padding: 2px;
}
.logos{
    padding-top: 15px;
    padding-left: 8px;
    padding-right: 8px;
}
.logos span{
    float: right;
    
}
.logos a{
    text-decoration: none;
    color: white;
    margin: 10px 10px 0 0;
}

@media only screen and (max-width: 768px){

    nav {
        width: 80%;
        height: 50px;
        background-color: #345984;
        margin: 0;
        padding: 0;
        color: white;
        margin: 0 auto;
        font-family: inter;
        font-size: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
    }
    nav span{
        margin-top: 5px;
    }
}