*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.pagos{
    background-color: rgb(49, 101, 146);
}
.pagoBody{
    width: 60%;
    margin:0 auto;
    font-family: 'Inter';
    font-weight: bold;
    height: 100%;
}
.datosColegio{
    text-align: center;
    width: 100%;
    padding: 20px;
    height: 150px;
}
.datosColegio h2{
    font-size: 34px;
}
.faxTel{
    padding-top: 10px;
    margin: 0 auto;
    width: 40%;
}
.fax{
    float: right;
}
.tel{
    float: left;
}
.apartado{
    margin-top: 30px;
}
.btn-consultar{
    float: left;
}
.btn-sig{
    float: right;
}
.datosCliente{
    margin-bottom: 10px;
}
.datosCliente p{
    margin-bottom: 5px;
}
iframe{
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    width: 100%;
    height: 100%;
}
.totalCuotas{
    margin-top: 20px;
    display: block;
    text-align: right;
}
.botonera{
    height: 80px;
    padding-bottom: 20px;
}
.terminos{
    margin-top: 10px;
    width: 100%;
    height: 20px;
    text-align: right;
}
.terminos input{
    margin-left: 5px;
    width: 2% ;
}

@media only screen and (max-width: 768px){
    .pagoBody{
        width: 98%;
        margin:0 auto;
        font-family: 'Inter';
        font-weight: bold;
        height: 100%;
        font-size: 12px;
    }
    .datosColegio{
        text-align: center;
        width: 100%;
        padding: 20px;
        height: 150px;
    }
    .datosColegio h2{
        font-size: 14px;
    }
    .faxTel{
        padding-top: 15px;
        margin: 0 auto;
        width: 65%;
    }
    .terminos{
        margin-top: 10px;
        width: 100%;
        height: 25px;
        text-align: center;
    }
    .terminos input{
        margin-left: 5px;
        width: 4% ;
    }
}
