.confirmacionBody{
    width: 80%;
    margin: 0 auto;
    font-family: inter;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 50px;
}
.btn-inicio{
    background-color: #627FA2;
}
.botonera{
    text-align: center;
    width: 100%;
}