
.banner{
    width: 100%;
    height: 190px;
    background: linear-gradient(0deg, rgba(55, 91, 132, 0.63), rgba(55, 91, 132, 0.63)), url("../assets/cmc.jpg") center no-repeat;
    background-size: cover;
    color: white;
    font-size: 24px;
    font-family: Inter;
}

.title{
width: 60%;
margin: 0 auto;
height: 190px;
padding-top: 90px;
}

.cuotasBody{
width: 60%;
margin: 0 auto;
display: flex;
flex-direction: column;

}
.nombreUsuario{
    padding-top: 20px;
    font-family: Inter;
    padding-bottom: 10px;
}
.tablas {
  
    font-family: Inter;
    font-size: 18px;
    border-collapse: collapse;
    width: 100%;
  }
  .tablas thead{
    
    background-color: #627E9F;
  }
  .tablas th {
    
    color: white ;
  }
  .tablas td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .tablas tr:nth-child(even){background-color: #f2f2f2;}
  
  .banner{
    text-align: center;
  }
  .tablas th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }
  .adelanto{
    font-size: 24px;
    font-family: inter;
    font-weight: bold;
    height: 100%;
  }
  .adelanto p{
    padding-bottom: 10px;
  }
  .adelanto h2{
    padding-bottom: 20px;
  }
  .dropdownAdelantos{
    width: 60px;
    height: 50px;
    padding-top: 40px;
   }
   #subTotal{
     width: 180px;
     height: 50px;
     margin-left: auto;
     font-size: 24px;
     margin-top: 20px;
     font-family: 'Inter';
   }
   .deshabilitado{
     pointer-events: none;
   }
   .buttons{
     height: 60px
   }

   @media only screen and (max-width: 768px){

    .cuotasBody{
      width: 98%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      }
    .tablas {
      font-family: "Inter";
      font-size: 10px; 
      margin-top: 10px;
      border-collapse: collapse;
    }
    .tablas td {
      border-bottom: 1px solid #ddd;
      padding:0;
      text-align: center;
    }
    .tablas th {
      padding-top: 6px;
      padding-bottom: 12px;
      text-align: center;
    }
    #subTotal{
      width: 100px;
      height: 40px;
      margin-left: auto;
      font-size: 14px;
      margin-top: 10px;
      font-family: 'Inter';
    }
    .nombreUsuario h2{
      font-size: 20px;
    }
    .adelanto p{
      font-size: 15px;
    }
    .dropdownAdelantos{
      width: 40px;
      padding-top: 60px;
    }
    .Dropdown-control{
      font-size: 12px;
      padding: 8px 20px 8px 5px;
    }
    .Dropdown-menu{
      font-size: 12px;
    }
   }