footer{
    position: sticky;
    top: 100%;
    padding-top: 5px;
    background-color: #254A82;
    color: white;
    font-family: inter;
    height: 50px;
    width: 100%;
    margin-top: auto;
}
.wrapper{
    padding-top: 10px;
    margin: 0 auto;
    width: 60%;
}
#copy{
    float: left;
}
#cole{
    float: right;
}

@media only screen and (max-width: 768px){
    footer{
        position: sticky;
        top: 100%;
        padding-top: 5px;
        background-color: #254A82;
        color: white;
        font-family: inter;
        height: 40px;
        width: 100%;
        margin-top: auto;
    }
    .wrapper{
        margin: 0 auto;
        width: 80%;
        font-size: 11px;
    }

}