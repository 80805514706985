.header{
    background-color: #254A82;
    width: 100%;
    height: 100px;
}

.header-container{
    width: 60%;
    margin: 0 auto;
    padding-top: 15px;
}
.logo-image{
    width: 35px;
    height: 20px;
}

@media only screen and (max-width: 768px){

    .header-container{
        width: 80%;
    }
}