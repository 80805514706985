.form-profesional{
    width: 60%;
    height: 300px;
    padding-top: 50px;
    margin: 0 auto;
    display: grid;
    margin-bottom: 100px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 33% 33% 33%;
    grid-template-areas: "izquierda-aba derecha-arr"
                         "izquierda-arr derecha-aba"
                         "btn-iz btn-der";
}
.form-profesional label{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}

.input{
    width: 80%;

    
}
.input-normal{
    height: 40px;
    font-size: 15px;
    outline: none;
    border: 1px solid #c0bfbf;
    border-radius: 5px;
}
.input-normal:focus{
    border: 2px solid #4E87FF;
}

input{
    padding-left: 5px;
    font-family: inter;
    width: 100%;
    
}
.area-iz-arr{
    margin-top: 20px;
    grid-area: izquierda-arr;
    
}
.area-iz-aba{
    grid-area: izquierda-aba;
}

.area-der-arr{
    grid-area: derecha-arr;
    justify-self: end;
}
.area-der-aba{
    margin-top: 20px;
    grid-area: derecha-aba;
    justify-self: end;
}
.botones{
    margin-top: 20px;
    width: 100px;
    padding: 5px;
    max-height: 35px;
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    border-style: none;
    border: 1px solid #c0bfbf;
    color: white;
    cursor: pointer;
}
.botones:hover{
    opacity: 0.8;
}
.botones:active{
  transform: translateY(3px);
}
.botones:disabled{
    background-color: grey;
    opacity: 80%;
    cursor: auto;
}
.btn-consultar{
    grid-area: btn-iz;
    background-color: rgb(74, 163, 74);
}
.btn-sig{
    grid-area: btn-der;
    justify-self: end;
    background-color: #4E87FF;
}
.inactivo{
    font-size: 20px;
    text-align: center;
    padding: 7px;
    width: 100%;
    height: 40px;
    background: red;
    color: white;
}
.bordeRojo {
    border: 1px solid red;
}

@media only screen and (max-width: 768px){

    .form-profesional{
        width: 80%;
        height: 450px;
        padding-top: 15px;
        margin: 0 auto;
        display: grid;
        margin-bottom: 100px;
        grid-template-columns:100%;
        grid-template-rows: 20% 20% 20% 20% 20%;
        grid-template-areas: "izquierda-aba ."
                             "izquierda-arr ."
                             "derecha-arr ."
                             "derecha-aba ."
                             "btn-iz .";
    }
    .input{
        width: 100%;
    
        
    }
    .btn-sig{
        grid-area: btn-iz;

        background-color: #4E87FF;
    }
    .area-iz-arr{
        margin-top: 0;
        grid-area: izquierda-arr;
        
    }
    .area-der-aba{
        margin-top: 0;
        grid-area: derecha-aba;
        justify-self: end;
    }
    .inactivo{
        font-size: 15px;
        text-align: center;
        padding: 10px;
        width: 100%;
        height: 45px;
        background: red;
        color: white;
    }
}